import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../../../components/SEO'
import Header from '../../../components/Header/Partners'
import Footer from '../../../components/Footer/Partners'
import ContentfulRichText from '../../../components/ContentfulRichText'
import {
  ContentfulFeatureContent,
  ContentfulHero,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulText,
} from '../../../../types/graphql-types'
import LayoutPartners from '../../../components/Layout/Partners/LayoutPartners'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const Hero = styled.section`
  @media (min-width: 1280px) {
    height: 480px;
  }

  h1 {
    ${tw`leading-none mb-8`}
  }
`

const FeaturedContent = styled.section`
  h2 {
    ${tw`mb-8`}
  }
`

const ItemDescription = styled.div`
  ${tw`w-full block`}

  h3 {
    ${tw`text-2xl`}
  }
  p {
    ${tw`mb-8 xl:mb-0`}
  }
`

const TextWrapper = styled.div`
  h2 {
    ${tw`mb-8`}
  }
`

export const query = graphql`
  query RegenceLanding {
    contentfulPage(externalName: { eq: "Regence Link" }) {
      id
      title
      seoTitle
      seoDescription
      noindex
      externalName
      references {
        ...hero
        ... on ContentfulMedia {
          __typename
          id
          name
          externalName
          altText
          media {
            file {
              url
            }
          }
        }
        ... on ContentfulFeatureContent {
          name
          externalName
          id
          references {
            ... on ContentfulMedia {
              name
              externalName
              id
              altText
              media {
                file {
                  url
                }
              }
            }
            ... on ContentfulText {
              name
              id
              externalName
              shortSimpleText
              longSimpleText {
                childMarkdownRemark {
                  html
                }
              }
              richText {
                __typename
                raw
                references {
                  ... on ContentfulGatsbyComponent {
                    __typename
                    contentful_id
                    name
                    id
                  }
                }
              }
            }
            ... on ContentfulList {
              name
              externalName
              id
              items {
                ... on ContentfulText {
                  name
                  shortSimpleText
                  longSimpleText {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                ... on ContentfulMedia {
                  name
                  externalName
                  id
                  altText
                  media {
                    file {
                      url
                    }
                    gatsbyImageData
                  }
                  link {
                    id
                    ctaLink
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const RegenceLandingPage = ({ data }: Props): JSX.Element => {
  const content = data.contentfulPage

  return (
    <LayoutPartners>
      <SEO
        title={content?.seoTitle as string}
        description={content?.seoDescription as string}
        noIndex={content?.noindex as boolean}
      />
      <Header
        classes="border-t-12 md:border-t-45 border-regence-blue"
        logo={(content.references as ContentfulMedia[]).map(logo => {
          if (logo?.externalName === 'Brand Logo') {
            return (
              <img
                key={logo.id}
                className="h-7 sm:h-10"
                src={logo.media?.file?.url as string}
                alt={logo.altText as string}
              />
            )
          }
        })}
      />
      <main id="main">
        {content?.references?.map(mainContent => {
          const hero = mainContent as ContentfulHero
          const featureContent = mainContent as ContentfulFeatureContent
          if (hero?.externalName === 'Regence Link Hero') {
            return (
              <Hero
                key={hero.id}
                className={`gradient-2-gray-1 md:h-360 mx-auto max-w-1920`}
              >
                <div className="flex h-full flex-wrap content-center">
                  <div
                    className={`container h-full grid-cols-12 pt-6 text-left md:grid md:pt-0`}
                  >
                    {(hero.references as ContentfulText[]).map(heroCopy => {
                      if (heroCopy.externalName === 'Hero Copy') {
                        return (
                          <div
                            key={heroCopy.id}
                            className="place-self-center md:col-span-7 lg:col-span-6 xl:col-span-4"
                            dangerouslySetInnerHTML={{
                              __html: heroCopy?.longSimpleText
                                ?.childMarkdownRemark?.html as string,
                            }}
                          ></div>
                        )
                      }
                    })}
                    {(hero.references as ContentfulMedia[]).map(heroImage => {
                      if (heroImage.externalName === 'Hero Large') {
                        return (
                          <img
                            key={heroImage.id}
                            className="mx-auto place-self-end md:col-span-5 lg:col-span-4 lg:col-start-7"
                            alt=""
                            src={heroImage.media?.file?.url as string}
                          />
                        )
                      }
                    })}
                  </div>
                </div>
              </Hero>
            )
          }
          if (
            featureContent?.externalName === 'Providing Care Feature Content'
          ) {
            return (
              <FeaturedContent
                key={featureContent.id}
                className="content-section grid-cols-2 place-items-center gap-12 lg:grid"
              >
                {(featureContent.references as ContentfulMedia[]).map(
                  feature => {
                    if (feature.externalName === 'Feature Content Image') {
                      return (
                        <img
                          key={feature.id}
                          alt={feature.altText as string}
                          src={feature.media?.file?.url as string}
                          className="mx-auto mb-8"
                        />
                      )
                    }
                  }
                )}
                <div className="mb-8 grid content-center">
                  {(featureContent.references as ContentfulText[]).map(
                    feature => {
                      if (feature.externalName === 'Feature Content Copy') {
                        return (
                          <ContentfulRichText
                            key={feature.id}
                            document={feature.richText}
                          />
                        )
                      }
                    }
                  )}
                </div>
              </FeaturedContent>
            )
          }
          if (featureContent?.externalName === 'Exclusive Features') {
            return (
              <section className="content-section-py" key={featureContent.id}>
                {(featureContent.references as ContentfulText[]).map(copy => {
                  if (copy.externalName === 'Feature Content Copy') {
                    return (
                      <TextWrapper
                        key={copy.id}
                        className="w-full md:text-center lg:mx-auto lg:w-10/12"
                        dangerouslySetInnerHTML={{
                          __html: copy.longSimpleText?.childMarkdownRemark
                            ?.html as string,
                        }}
                      ></TextWrapper>
                    )
                  }
                })}
                <div className="grid-cols-12 lg:mt-24 lg:grid">
                  {(featureContent.references as ContentfulMedia[]).map(
                    media => {
                      if (media.externalName === 'Features Image') {
                        return (
                          <div
                            key={media.id}
                            className="col-span-4 col-start-5 row-span-4 row-start-1"
                          >
                            <img
                              key={media.id}
                              alt={media.altText as string}
                              src={media.media?.file?.url as string}
                              className="-top-16 mx-auto lg:relative"
                            />
                          </div>
                        )
                      }
                    }
                  )}
                  {(featureContent.references as ContentfulList[]).map(copy => {
                    if (copy.externalName === 'Features') {
                      return (
                        <React.Fragment key={copy.id}>
                          {(copy.items as ContentfulText[]).map(list => (
                            <ItemDescription
                              key={list.name}
                              className="col-span-4"
                            >
                              <h3>{list.shortSimpleText}</h3>
                              <div
                                className="block w-full"
                                dangerouslySetInnerHTML={{
                                  __html: list.longSimpleText
                                    ?.childMarkdownRemark?.html as string,
                                }}
                              ></div>
                            </ItemDescription>
                          ))}
                        </React.Fragment>
                      )
                    }
                  })}
                  {(featureContent.references as ContentfulList[]).map(list => {
                    if (list.externalName === 'App Download Buttons') {
                      return (
                        <div
                          key={list.id}
                          className="col-span-4 col-start-5 row-start-4 mx-auto place-self-end text-center"
                        >
                          {(list.items as ContentfulMedia[]).map(buttons => {
                            if (
                              buttons.externalName === 'Feature Content Image'
                            ) {
                              return (
                                <a
                                  key={buttons.id}
                                  className="px-1 md:px-2"
                                  href={buttons.link?.ctaLink as string}
                                  aria-label={buttons.name as string}
                                >
                                  <GatsbyImage
                                    image={buttons.media?.gatsbyImageData}
                                    alt={buttons.altText as string}
                                  />
                                </a>
                              )
                            }
                          })}
                        </div>
                      )
                    }
                  })}
                </div>
              </section>
            )
          }
        })}
      </main>
      <Footer color="bg-regence-blue" data={data} />
    </LayoutPartners>
  )
}

export default RegenceLandingPage
